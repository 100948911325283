var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.detailsView)?_c('div',[(_vm.applicationElements)?_c('div',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('v-btn',{attrs:{"large":"","depressed":"","tile":""},on:{"click":_vm.createApplicationElement}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-application-cog-outline ")]),_vm._v(" Add Element ")],1),_c('v-col',{attrs:{"cols":"4"}},[_c('search-text-field',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-checkbox',{attrs:{"label":"Show disabled"},on:{"change":_vm.getApplicationElements},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}})],1),_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.applicationElements,"search":_vm.search},on:{"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.installerType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInstallerType(item.installerType))+" ")]}},{key:"item.platform",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPlatform(item.platform))+" ")]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('readonly-checkbox',{attrs:{"value":!item.disabled}})]}},{key:"item.detectionMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetectionMethod(item.detectionMethod))+" ")]}},{key:"item.elementName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ApplicationElementDetails', params: { Id: item.id } }}},[_vm._v(_vm._s(item.elementName))])]}}],null,true)},'v-data-table',_vm.$util.dataTableAttributes(),false)),_c('create-edit-application-element',{ref:"CreateEditApplicationElementRef",on:{"applicationelement-updated":_vm.getApplicationElements}})],1):_vm._e()]):_c('application-elements-details',{on:{"applicationelement-updated":_vm.getApplicationElements}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }